import {useEffect, useState, useMemo, useCallback} from 'react';

export function Analytics() {
  function gtag(...items: any[]) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    gtag('js', new Date());
    gtag('config', 'G-TEQ49K1RJW');
  }, []);

  return null;
}
